<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" transition="scroll-y-transition" :max-width="475" :nudge-width="475" offset-y attach :z-index="1001">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="border-10" :style="menu ? { border: `1px solid ${$vuetify.theme.themes.light.secondary} !important` } : {}" elevation="0" color="lightsecondary" :ripple="false" v-bind="attrs" v-on="on">
          <v-icon left color="secondary">
            mdi-map-marker-radius
          </v-icon>
          <span v-if="!loading" class="text-subtitle-2 text-capitalize font-weight-bold secondary--text">{{ getValueInCookie() || 'Do we Serve Your Area?' }}</span>
          <v-skeleton-loader v-if="loading" width="125px" height="28px" type="text" class="d-flex align-center"></v-skeleton-loader>
          <v-icon right x-small color="secondary">
            {{ !menu ? 'mdi-triangle-down' : 'mdi-triangle' }}
          </v-icon>
        </v-btn>
      </template>
      <v-card outlined class="border-10" :style="{ border: `1px solid ${$vuetify.theme.themes.light.secondary} !important`, backgroundColor: $vuetify.theme.currentTheme.lightsecondary }">
        <v-card-title class="d-flex justify-center pa-2 pb-0">
            <v-spacer></v-spacer>
            <v-icon right color="#4E4E4E" @click="closeMenu">mdi-close</v-icon>
        </v-card-title>
        <v-card-title class="px-4 py-0 pb-1">
            <span class="text-subtitle-1 font-weight-bold"> {{ i18n.t('enterYourZipCode') }}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="searchForm" lazy-validation @submit.prevent>
            <v-text-field v-model="searchValue" :placeholder="i18n.t('enterZipCode')" outlined dense color="secondary" class="custom-text-field rounded-lg" :readonly="readOnly" :rules="formRules.zipRules" :error-messages="displayErrorMessage" :success-messages="displaySuccessMessage" :success="displaySuccessMessage !== ''">
              <template v-slot:prepend-inner>
                <v-icon color="secondary">mdi-map-marker-radius</v-icon>
              </template>
              <template v-slot:append-outer>
                <v-slide-x-transition v-if="!getURLInCookie()" leave-absolute>
                  <v-btn @click="setCookieValue" outlined color="secondary" height="40px" class="mt-0 rounded-lg text-subtitle-1 text-capitalize custom-shadow" :loading="loading">
                    <strong>{{ i18n.t('updateLocation') }}</strong>
                  </v-btn>
                </v-slide-x-transition>
                <v-slide-x-transition v-if="getURLInCookie()" leave-absolute>
                  <v-btn @click="clearInput" outlined color="secondary" height="40px" class="mt-0 rounded-lg text-subtitle-1 text-capitalize custom-shadow" :loading="loading">
                    <v-icon color="secondary">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </v-slide-x-transition>
              </template>
              <template v-slot:append>
                <v-slide-x-transition v-if="displaySuccessMessage" leave-absolute>
                  <v-icon color="success">mdi-check-circle</v-icon>
                </v-slide-x-transition>
                <v-slide-x-transition v-if="displayErrorMessage" leave-absolute>
                  <v-icon color="error">mdi-alert-circle</v-icon>
                </v-slide-x-transition>
              </template>
              <template v-slot:message="{ message }">
                <span v-if="message === i18n.t('availableService')" class="text-subtitle-2 font-weight-bold primary--text">{{ message }}</span>
                <span v-else-if="message === i18n.t('sorryService')" class="text-subtitle-2 font-weight-bold error--text">{{ message }}</span>
                <span v-else>{{ message }}</span>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  name: 'ZipChecker',
  props: {
    redirect: Boolean,
  },
  data() {
    return {
      i18n: i18n,
      loading: false,
      menu: false,
      readOnly: false,
      displaySuccessMessage: '',
      displayErrorMessage: '',
      searchValue: '',
      COOKIENAME: 'userLocation',
      locationData: {},
    };
  },
  computed: {
    formRules() {
      return {
        zipRules: [
          v => !!v || this.i18n.t('formRules.zip.required'),
          v => (v && v.length >= 2 && v.length <= 10) || this.i18n.t('formRules.zip.nbCaracters'),
          v => !/^0{5,}$/.test(v) || this.i18n.t('formRules.zip.invalid'), // Disallow fake ZIP codes like '00000'
          v => /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/.test(v) || this.i18n.t('formRules.zip.required'),
        ],
      }
    }
  },
  async mounted() {
    if(this.$cookies.isKey(this.COOKIENAME)){
      this.searchValue = this.getValueInCookie();
      this.displaySuccessMessage = this.i18n.t('availableService');
    }
  },
  methods: {
    async setCookieValue() {
      if(!this.readOnly && this.$refs.searchForm.validate()){
        this.gTagSendEvents('Zip Checker - Check My Area', this.$cookies.get(this.COOKIENAME)?.zip, "select_content", "engagement");
        await this.fetchMuleAPIDev();
      }
    },
    getValueInCookie() {
      const { city: CITY } = this.$cookies.get(this.COOKIENAME) || {};
      return CITY ? ` ${CITY.charAt(0).toUpperCase() + CITY.slice(1).toLowerCase()}` : null;
    },
    getURLInCookie() {
      const URL = this.$cookies.get(this.COOKIENAME)?.url;
      return URL && URL !== window.location.hostname ? URL : null;
    },
    async fetchMuleAPIDev(useZipFromCookie) {
      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_MULEAPIDEV_URL}`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              "zipcode": useZipFromCookie || this.searchValue
            }),
          }
        );
        const district = await response.json();
        if(!district?.DoWeServe){
          this.displayErrorMessage = i18n.t('sorryService');
        }else{
          this.locationData = district;
          const COOKIEDATA = {
            zip: district?.DistrictDetail?.zip,
            city: district?.DistrictDetail?.city,
            url: district?.DistrictDetail?.website,
            phone: district?.DistrictDetail?.phone
          };
          this.$cookies.set(this.COOKIENAME, COOKIEDATA);
          this.searchValue = this.getValueInCookie();
          this.$refs.searchForm?.resetValidation();
          this.setReadOnly(true);
          this.displaySuccessMessage = i18n.t('availableService');
          this.displayErrorMessage = '';
        }
      } catch (error) {
        this.displaySuccessMessage = '';
        this.displayErrorMessage = i18n.t('anErrorOccurred');
      } finally {
        this.loading = false;
      }
    },
    setReadOnly(value) {
      this.readOnly = value;
    },
    closeMenu() {
      this.menu = false;
    },
    clearInput() {
      this.searchValue = '';
      this.displaySuccessMessage = '';
      this.displayErrorMessage = '';
      this.$cookies.remove(this.COOKIENAME);
      this.setReadOnly(false);
    },
    gTagSendEvents(label, value, action, category, leadType, leadCategory, district) {
        if (window.google_tag_manager && typeof window.dataLayer !== "undefined" && typeof action !== "undefined" && typeof category !== "undefined" && typeof label !== "undefined" && typeof value !== "undefined") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eventTracking",
              action: action,
              event_category: category,
              event_label: label,
              value: value,
              lead_type: leadType,
              lead_category: leadCategory,
              lead_district: district,
            });
        }
    }
  }
}
</script>

<style scoped>
.v-application .rounded-lg, .border-10 {
  border-radius: 10px !important;
}
.custom-shadow, ::v-deep .v-input__slot {
  background-color: white !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important;
}
::v-deep .custom-text-field .v-input__control .v-input__slot {
  background: white !important;
}
::v-deep .theme--light.v-input--is-disabled, ::v-deep .theme--light.v-input--is-disabled input, ::v-deep .theme--light.v-input--is-disabled textarea, ::v-deep .theme--light.v-icon.v-icon.v-icon--disabled {
  color: initial !important;
}
::v-deep .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 1 !important;
  color: #4e4e4e;
}
.v-menu__content {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  border-radius: 10px 10px 10px 10px !important;
}
::v-deep .v-input__prepend-outer {
  margin: 18px 9px !important;
}
::v-deep .v-input__append-outer {
  margin-top: 0px !important;
}
::v-deep .v-skeleton-loader__text {
  margin-bottom: 0px;
}
</style>
