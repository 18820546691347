import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#175E45',
                secondary: '#013e74',
                accent: '#616161',
                error: '#d00004',
                info: '#0000FF',
                darksecondary: '#003F6F',
                lightsecondary: '#F0F7FE',
            },
        },
    },
});